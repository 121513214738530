import React from "react"
import { useLocation } from "react-router-dom"

import SessionManager from "api/SessionManager"
import favicon from "assets/img/pixel-brand.svg"
import { AutoNewsletterDeliveryAlert } from "components/Alert/AutoNewsletterDeliveryAlert"
import { PageContainer, PageContent } from "components/AppLayout/PageContainer"
import { TopAlertBar } from "components/AppLayout/TopAlertBar"
import Meta from "components/Meta"
import Userlane from "components/Userlane/Userlane"
import usePrefix from "utils/usePrefix"

import { CookiePolicy } from "./CookiePolicy"
import { PageFooter } from "./PageFooter"
import { PageHeader } from "./PageHeader"

import DEVRoleBtn from "../__DEVRoleBtn"

type Props = {
  children: React.ReactNode
}

export const AppLayout = ({ children }: Props) => {
  const tGeneral = usePrefix("General")
  const { pathname } = useLocation()
  const isAOPublisher = SessionManager.getIsAOPublisher()
  const session = SessionManager.getSession()

  return (
    <>
      <Meta title={tGeneral("app_name")} favicon={favicon} />
      <PageContainer>
        <PageHeader />

        <TopAlertBar />

        {pathname.includes("/news/ao/edit") && isAOPublisher && (
          <AutoNewsletterDeliveryAlert />
        )}

        <PageContent>{children}</PageContent>
        <PageFooter />
      </PageContainer>

      <CookiePolicy />
      {session?.realm === "Public" && (
        <Userlane
          appId="ndgewnl3gr"
          user={{
            id: session?.borgUserId,
            sapId: session?.borgClientId,
            userRole: session?.userRole,
            realm: session?.realm,
            isFirstLogon: session?.isFirstLogon
          }}
        />
      )}
      {process.env.NODE_ENV === "development" && <DEVRoleBtn />}
    </>
  )
}
