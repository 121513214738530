import React, { lazy, Suspense } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import { AppLayout } from "components/AppLayout"
import { AppTour } from "components/AppTour"
import { Loading } from "components/Loading"
import RefreshToken from "components/RefreshToken"
import { ScrollToTop } from "components/ScrollToTop"
import { AppPages } from "pages/AppPages"

import LoginManager from "./LoginManager"

const ThankYouPages = lazy(() => import("./pages/ThankYouPages"))
const UnsubscribePages = lazy(() => import("./pages/UnsubscribePages"))
const EmbeddedLeadFormPages = lazy(
  () => import("./pages/EmbeddedLeadFormPages")
)
const App = () => {
  return (
    <Suspense fallback={<Loading fullHeight />}>
      <Router>
        <Routes>
          <Route path="thankyou/*" element={<ThankYouPages />} />
          <Route path="unsubscribe/*" element={<UnsubscribePages />} />
          <Route
            path="leadregistration/*"
            element={<EmbeddedLeadFormPages />}
          />

          <Route
            path="/*"
            element={
              <LoginManager>
                <AppLayout>
                  <ScrollToTop />

                  <AppPages />

                  <AppTour />
                  <RefreshToken />
                </AppLayout>
              </LoginManager>
            }
          />
        </Routes>
      </Router>
    </Suspense>
  )
}

export default App
