import React from "react"

interface LoadingProps {
  fullHeight?: boolean
}

export const Loading = ({ fullHeight }: LoadingProps) => {
  return (
    <div
      className={`${
        fullHeight
          ? "fixed inset-0 flex items-center justify-center"
          : "h-[calc(100dvh - 224px)] flex items-center justify-center"
      }`}
    >
      <div className="h-5 w-5 animate-spin rounded-full border-2 border-dashed border-muted-foreground border-t-transparent" />
    </div>
  )
}
