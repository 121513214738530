import { PopperContentProps } from "@radix-ui/react-popper"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import * as React from "react"

import { cn } from "lib/utils"

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger
const TooltipPortal = TooltipPrimitive.Portal

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md border-none bg-popover-foreground/90 px-3 py-1.5 text-sm text-popover shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const TooltipComponent = ({
  triggerContent,
  tooltipContent,
  align = "start",
  asPortal = false,
  disabled = false
}: {
  triggerContent: React.ReactNode
  tooltipContent: React.ReactNode
  align?: PopperContentProps["align"]
  asPortal?: boolean
  disabled?: boolean
}) => {
  const TooltipContentComponent = (
    <TooltipContent align={align} className="max-w-96 text-wrap">
      {tooltipContent}
    </TooltipContent>
  )

  return (
    <Tooltip>
      <TooltipTrigger asChild disabled={disabled}>
        {triggerContent}
      </TooltipTrigger>
      {asPortal ? (
        <TooltipPortal>{TooltipContentComponent}</TooltipPortal>
      ) : (
        TooltipContentComponent
      )}
    </Tooltip>
  )
}

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipComponent
}
