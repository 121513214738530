import React from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  AccordionProps,
  GenericAccordion
} from "components/Accordion/GenericAccordion"
import Icon from "components/Icons"
import {
  selectIsFetching,
  selectNotifications,
  markAsRead,
  removeNotification
} from "store/notifications"
import { cleanHTML } from "utils/sanitization"
import { isTagManager } from "utils/tagManager"

import { Title } from "./Title"

const NotificationContent = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const notifications = useSelector(selectNotifications)

  const onExpandChange = (id: string) => {
    const notification = notifications.find((not) => not.id === id)
    if (!notification?.isRead) {
      dispatch(markAsRead(id))
    }
    if (isTagManager) {
      const tagManagerArgs = {
        event: "Notification list",
        dataGtm: `notifications-position-open-${id}`
      }

      window.dataLayer.push(tagManagerArgs)
    }
  }

  const onDeleteNotification = (id: string) => {
    dispatch(removeNotification(id))
  }

  if (isFetching) {
    return (
      <div className="flex h-[500px] w-[500px] items-center justify-center overflow-auto">
        <Icon type="loading" className="animate-spin" />
      </div>
    )
  }

  const notificationItems: AccordionProps["items"] = notifications.map(
    (notification) => ({
      trigger: (
        <Title
          title={notification.title}
          date={notification.createAtUtc}
          isRead={notification.isRead}
        />
      ),
      name: `notification-${notification.id}`,
      accordionContent: (
        <div className="relative h-full w-full">
          <iframe
            ref={(el) => {
              if (el?.contentWindow) {
                const adjustHeight = () => {
                  const iframeDoc = el.contentWindow?.document
                  const iframeHeight =
                    iframeDoc?.documentElement.scrollHeight || 0

                  if (iframeDoc) {
                    iframeDoc.body.style.overflow = "hidden"
                    iframeDoc.body.style.transform = "scale(0.95)"
                    iframeDoc.body.style.transformOrigin = "top left"

                    const style = iframeDoc.createElement("style")
                    style.textContent = `
                    body {
                      font-family: 'Fira Sans', sans-serif;
                    }
                    a {
                      color: #007AC3; 
                      text-decoration: none;
                    }
                    a:hover {
                      color: #007AC3; 
                      text-decoration: underline;
                    }
                    li {
                    line-height: 22px;
                    }
                 `
                    iframeDoc.head.appendChild(style)
                  }

                  el.style.height = `${iframeHeight}px`
                }

                el.onload = adjustHeight
                el.contentWindow.onresize = adjustHeight
              }
            }}
            srcDoc={cleanHTML(notification.contentHtml)}
            data-gtm="gtm-notifications-list-item-contentHtml"
            className="w-full"
            style={{ height: "auto", overflow: "hidden" }}
          />
          <Icon
            type="delete"
            onClick={() => onDeleteNotification(notification.id)}
            className="absolute right-1 top-1 h-4 w-4 cursor-pointer stroke-secondary-foreground"
          />
        </div>
      ),
      onTriggerClick: () => onExpandChange(notification.id)
    })
  )

  return (
    <div className="h-[500px] w-[500px] overflow-hidden overflow-y-auto">
      <div className="h-full px-5">
        <GenericAccordion items={notificationItems} />
      </div>
    </div>
  )
}

export default NotificationContent
